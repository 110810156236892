<template>
  <div class="user-coupon">
    <!-- <van-tabs v-model="active" sticky @click="toggle">
      <van-tab :title="item.title" v-for="(item,ins) in tabData" :key="ins">
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          :immediate-check="false"
          :offset="10"
          style="padding: 15px 15px 50px 15px"
        >
          <div
            v-for="(item, index) in goodsList"
            :key="index"
            @click="goGoodsDetail(item.id)"
            class="coupons"
            v-if="dataStatus"
          >
            <div
              class="base-coupons"
              :class="item.status == 1 ? 'coupons-not' : 'coupons-end'"
            >
              <div class="coupons-left">
                <span v-if="item.type == 1">
                  {{ parseInt(item.rate) }}
                  <span class="rate-company">折</span>
                </span>
                <span v-else>
                  <span class="rate-company">￥</span>
                  {{ item.rate }}
                </span>
              </div>
              <div class="coupons-right">
                <div class="coupons-right-bottom">
                  <span>满{{ item.lower }}元可用</span>
                </div>

                <div class="coupons-right-middle">
                  <span>使用规则：{{ item.detail }}</span>
                </div>
                <div class="coupons-right-top">
                  <span>有效期：</span>
                  <span>{{ item.start_time.split(" ")[0] }}</span>
                  <span>至</span>
                  <span>{{ item.end_time.split(" ")[0] }}</span>
                </div>
                <div class="userShop">
                  <span>使用店铺：</span>
                  <span>{{ item.s_name }}</span>
                </div>
              </div>
            </div>
          </div>
          <van-empty :image="emptyImg" description="暂无优惠券" v-else />
        </van-list>
      </van-tab>
    </van-tabs> -->
    <van-empty description="努力开发中..." />
  </div>
</template>

<script>
import { reactive } from "vue";
import { ref } from "vue";
import { getUserCoupon } from "../../api/user.js";

export default {
  data() {
    return {
      tabData: [
        {
          title: "未使用",
          type: 1,
        },
        {
          title: "已使用",
          type: 2,
        },
        {
          title: "已失效",
          type: 3,
        },
      ],
      active: 0,
      url: this.url.imageUrl,
      nullTip: "空空如也~",
      limit: 10, // 每页条数
      page: 1, // 页码
      type: 1,
      goodsList: [
        {
          add_time: "2021-07-06 16:32:19",
          detail: "满20可用，不叠加",
          end_time: "2021-08-02 16:14:00",
          id: 102,
          image: "/upload/image/20210702/ceafae90a3f26c0cadc49c4f6418a474.png",
          lower: 20,
          name: "满20优惠3元",
          rate: 3,
          remark: "暂无",
          s_name: "绝味鸭脖",
          sid: 11,
          start_time: "2021-07-02 16:14:00",
          status: 1,
          trade_id: 1,
          type: 2,
        },
      ], // 请求数据
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      dataStatus: true,
      emptyImg: require("@/assets/zwyhq.png"),
    };
  },
  mounted() {
    this.getUserCoupon();
  },
  methods: {
    toggle(name) {
      this.type = name + 1;
      this.page = 1;
      this.limit = 10;
      this.upFinished = false;
      // this.goodsList = [];
      this.getUserCoupon();
    },
    getUserCoupon() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {};
      params.status = this.type;
      params.page = this.page;
      params.limit = this.limit;
      // getUserCoupon(params).then((res) => {
      // 	// console.log(res)
      // 	const rows = res.data.data
      // 	// console.log(rows.length)
      // 	if (rows.length === 0) {
      // 		// 加载结束
      // 		this.upFinished = true
      // 		if (this.page == 1) {

      // 			this.dataStatus = false
      // 			this.$toast.clear()
      // 		}
      // 		return
      // 	} else {
      // 		this.dataStatus = true
      // 	}
      // 	if (rows.length < this.limit) {
      // 		// 最后一页不足10条的情况
      // 		this.upFinished = true
      // 	}
      // 	// 处理数据
      // 	if (this.page === 1) {
      // 		this.goodsList = rows
      // 	} else {
      // 		this.goodsList = this.goodsList.concat(rows)
      // 	}
      // 	// console.log(this.goodsList)
      // 	this.$toast.clear()
      // })
    },
    goGoodsDetail(id) {
      console.log(id);
    },
  },
};
</script>

<style lang="less" scoped>
.user-coupon {
  .coupons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .coupons-not {
      background: radial-gradient(
            circle at right top,
            transparent 10px,
            #fc6228 0
          )
          top left / 80px 51% no-repeat,
        radial-gradient(circle at right bottom, transparent 10px, #fc6228 0)
          bottom left / 80px 51% no-repeat,
        radial-gradient(circle at left top, transparent 10px, #ffffff 0) top
          right / 220px 51% no-repeat,
        radial-gradient(circle at left bottom, transparent 10px, #ffffff 0)
          bottom right / 220px 51% no-repeat;
    }

    .coupons-end {
      background: radial-gradient(
            circle at right top,
            transparent 10px,
            #a1a1a1 0
          )
          top left / 80px 51% no-repeat,
        radial-gradient(circle at right bottom, transparent 10px, #a1a1a1 0)
          bottom left / 80px 51% no-repeat,
        radial-gradient(circle at left top, transparent 10px, #ffffff 0) top
          right / 220px 51% no-repeat,
        radial-gradient(circle at left bottom, transparent 10px, #ffffff 0)
          bottom right / 220px 51% no-repeat;
    }

    .base-coupons {
      width: 300px;
      height: 100px;
      display: flex;
      // align-items: center;
      position: relative;

      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));

      .coupons-left {
        // padding-left: 15px;
        width: 80px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;

        span {
          font-size: 32px;
          font-weight: bold;
          color: #fff;
          letter-spacing: -2px;
        }

        .rate-company {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .coupons-right {
        width: 220px;
        padding: 11px;
        box-sizing: border-box;

        .coupons-right-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;

          span {
            color: #999999;
            font-size: 12px;
          }
        }
        .coupons-right-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;
          span {
            color: #000;
            font-size: 16px;
          }
        }

        .userShop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;

          span {
            color: #999999;
            font-size: 12px;
          }
        }

        .coupons-right-middle {
          span {
            color: #999999;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .base-coupons::before {
      content: "";
      height: 80px;
      border: 1px dashed #fff;
      position: absolute;
      left: 80px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .base-coupons::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 5px;
      top: 0;
      right: -5px;
      background-image: linear-gradient(
          to bottom,
          #ffffff 5px,
          transparent 5px,
          transparent
        ),
        radial-gradient(10px circle at 5px 10px, transparent 5px, #ffffff 5px);
      background-size: 5px 15px;
    }
  }
}
</style>
